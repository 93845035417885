import {
  Component,
  ElementRef,
  AfterViewInit,
  inject,
  input,
  contentChildren,
  viewChild,
  computed,
  signal,
  model,
} from '@angular/core';
import { MatRipple } from '@angular/material/core';
import { ExpandableComponent, MuloNavLink } from '@exl-ng/mulo-common';

import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'mulo-page-section',
  templateUrl: './page-section.component.html',
  styleUrls: ['./page-section.component.scss'],
  host: {
    class: 'mulo-page-section',
    '[class.is-overflow-hidden]': 'hideOverflow()',
  },
  imports: [MatRipple, MatIcon, TranslateModule],
})
export class PageSectionComponent implements AfterViewInit {
  private el = inject(ElementRef);

  readonly hideOverflow = model(false);
  readonly title = input<string>(undefined);
  readonly slug = computed(() => this.title().toLowerCase().replace(/ /g, '_'));
  readonly description = input<string>(undefined);

  readonly imageSrc = input<string>(undefined);
  readonly imageAlt = input<string>(undefined);
  readonly imageOnRight = input(false);
  readonly imageFullWidth = input(false);

  readonly required = input(false);
  readonly requiredText = input('Required');

  readonly expandableCmps = contentChildren(ExpandableComponent, {
    descendants: true,
  });

  readonly ripple = viewChild(MatRipple);
  readonly highlightActive = signal(false);

  readonly navLink = computed<MuloNavLink>(() => {
    if (this.title()) {
      const name = this.slug();

      return {
        label: this.title(),
        name,
        url: `#${name}`,
        required: this.required(),
      };
    } else {
      return undefined;
    }
  });

  ngAfterViewInit() {
    if (this.expandableCmps().length > 0) {
      this.hideOverflow.set(true);
    }
  }

  public highlight() {
    this.focusSectionOnHighlight();

    const el = this.el.nativeElement.getBoundingClientRect();
    this._launchRipple(el.left, el.top + el.height / 2);
  }

  private focusSectionOnHighlight() {
    this.el.nativeElement.tabIndex = 0;
    this.el.nativeElement.focus();
    this.el.nativeElement.tabIndex = -1;
  }

  private _launchRipple(x: number = 0, y: number = 0) {
    this.highlightActive.set(true);
    this.ripple().fadeOutAll();
    const rippleRef = this.ripple().launch(x, y, {
      persistent: true,
      centered: false,
      animation: { exitDuration: 600 },
    });
    setTimeout(() => {
      rippleRef.fadeOut();
      setTimeout(() => {
        this.highlightActive.set(false);
      }, 400);
    }, 600);
  }
}
