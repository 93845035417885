import {
  Component,
  inject,
  contentChildren,
  input,
  computed,
  signal,
  effect,
} from '@angular/core';
import { Location, NgTemplateOutlet } from '@angular/common';
import {
  GrowInAnimation,
  GrowOutAnimation,
  HorizontalNavComponent,
  InViewDirective,
  OpacityOutAnimation,
  VerticalNavComponent,
} from '@exl-ng/mulo-common';
import { MediaQueryAlias, MediaService } from '@exl-ng/mulo-core';
import { MULO_CONTAINER_WIDTH } from '../../models';
import { PageSectionComponent } from '../page-section/page-section.component';
import { PageMastComponent } from '../page-mast/page-mast.component';
import {
  NavSize,
  NavMainLayoutComponent,
  CenteredBlockLayoutComponent,
} from '../../layouts';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { PagePlaceholderComponent } from '../page-placeholder/page-placeholder.component';

@Component({
  selector: 'mulo-page-base',
  templateUrl: './page-base.component.html',
  styleUrls: ['./page-base.component.scss'],
  host: { class: 'mulo-page-base' },
  animations: [OpacityOutAnimation, GrowOutAnimation, GrowInAnimation],
  imports: [
    CenteredBlockLayoutComponent,
    PagePlaceholderComponent,
    NgTemplateOutlet,
    InViewDirective,
    NavMainLayoutComponent,
    VerticalNavComponent,
    RouterLink,
    MatIcon,
    HorizontalNavComponent,
  ],
})
export class PageBaseComponent {
  private location = inject(Location);
  media = inject(MediaService);

  readonly loading = input(true);
  readonly contentWidth = input<number>(MULO_CONTAINER_WIDTH.READING);
  readonly navWidth = input<NavSize>(150);
  readonly backToTopLabel = input('Title');
  readonly hNavMediaCondition = input<MediaQueryAlias>('lt-lg');

  /** Highlight a specific section briefly - used to help focus on a section that was navigated to within the page */
  readonly sectionHighlight = input<string>(undefined);

  readonly stickyOffset = input(undefined);
  readonly navBehavior = input<'sticky' | 'fixed' | 'default'>('sticky');
  readonly navPosition = input<'top' | 'bottom' | 'left' | 'right'>(undefined);
  readonly rtl = input(false);

  sections = contentChildren(PageSectionComponent, { descendants: true });
  _masts = contentChildren(PageMastComponent, { descendants: true });

  readonly showBackToTop = signal(false);

  private allAnchors = computed(() =>
    this.sections().map((item, index) => ({ ...item.navLink(), id: index })),
  );
  readonly anchors = computed(() =>
    this.allAnchors().filter((link) => link.label != ''),
  );

  readonly requiredLabel = input('Required');

  constructor() {
    effect(() => {
      if (this.sectionHighlight()) {
        this.highlightSection(this.sectionHighlight());
      }
    });
  }

  containerWidth = computed(() => {
    return this.contentWidth() - Number(this.navWidth());
  });

  navStuck(state) {
    this.showBackToTop.set(state);
  }

  onAnchorNav(ev) {
    if (ev && ev.name) this.highlightSection(ev.name);
  }

  handleBackToTop() {
    const path = this.location.path();
    this.location.replaceState(path);
    this._masts().at(0)?.highlight();
  }

  highlightSection(sectionName: string) {
    if (!sectionName) return;
    this.sections()
      ?.find((s) => s.slug() === sectionName)
      ?.highlight();
  }
}
